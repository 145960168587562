var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h1',{staticClass:"bold-header-text app-header mb-3"},[_c('confirmation',{ref:"Confirmation"}),(_vm.companyInfo.parentCompanyID)?_c('span',[_vm._v("Additional Site")]):_c('span',[_vm._v("Company")]),_vm._v(" "+_vm._s(_vm.companyInfo.companyID)+" ")],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('LabelWithInfoTootlip',{attrs:{"label":_vm.$t('company.company-legal-name'),"tooltipText":"The legal name of your business must match paperwork sent to your state or federal regulatory bodies."}}),_c('b-form-input',{attrs:{"type":"text","state":_vm.$v.companyInfo.companyLegalName.$dirty
              ? !_vm.$v.companyInfo.companyLegalName.$error
              : null},on:{"input":_vm.$v.companyInfo.companyLegalName.$touch},model:{value:(_vm.companyInfo.companyLegalName),callback:function ($$v) {_vm.$set(_vm.companyInfo, "companyLegalName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.companyLegalName"}}),(!_vm.$v.companyInfo.companyLegalName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be fewer than 75 characters! ")]):_vm._e(),(
            !_vm.$v.companyInfo.companyLegalName.required && _vm.$v.companyInfo.companyLegalName.$dirty
          )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('LabelWithInfoTootlip',{attrs:{"label":_vm.$t('company.dba-company-name'),"tooltipText":"The Doing Business As (DBA) name is also referred to as an assumed name, fictitious business name, or trade name."}}),_c('b-form-input',{attrs:{"type":"text","state":_vm.$v.companyInfo.dbaCompanyName.$dirty ? !_vm.$v.companyInfo.dbaCompanyName.$error : null},on:{"input":_vm.$v.companyInfo.dbaCompanyName.$touch},model:{value:(_vm.companyInfo.dbaCompanyName),callback:function ($$v) {_vm.$set(_vm.companyInfo, "dbaCompanyName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.dbaCompanyName"}}),(!_vm.$v.companyInfo.dbaCompanyName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be fewer than 75 characters! ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('LabelWithInfoTootlip',{attrs:{"label":"Tax ID","tooltipText":"A Taxpayer Identification Number (TID) is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entities operating in the United States and its territories."}}),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.taxMask),expression:"taxMask"}],attrs:{"state":_vm.$v.companyInfo.maskedFederalIDNumber.$dirty
              ? !_vm.$v.companyInfo.maskedFederalIDNumber.$error
              : null},model:{value:(_vm.companyInfo.maskedFederalIDNumber),callback:function ($$v) {_vm.$set(_vm.companyInfo, "maskedFederalIDNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.maskedFederalIDNumber"}}),(!_vm.$v.companyInfo.maskedFederalIDNumber.minLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyInfo.maskedFederalIDNumber.$error}},[_vm._v(" Must have at least 9 digits. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Organization Website"}},[_c('b-form-input',{attrs:{"state":_vm.$v.companyInfo.website.$dirty ? !_vm.$v.companyInfo.website.$error : null},on:{"input":_vm.$v.companyInfo.website.$touch},model:{value:(_vm.companyInfo.website),callback:function ($$v) {_vm.$set(_vm.companyInfo, "website", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.website"}}),(!_vm.$v.companyInfo.website.url && _vm.$v.companyInfo.website.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be a valid URL. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Program(s) of Interest"}},[_c('multiselect',{class:{
            'multiselect-is-invalid': _vm.$v.companyInfo.programsOfInterest.$dirty
              ? _vm.$v.companyInfo.programsOfInterest.$error
              : null,
          },attrs:{"options":_vm.groupedPrograms,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":_vm.$t('dropdown.select-a-program'),"group-values":"options","group-label":"label","label":"programDescription","track-by":"programID","preselect-first":false},on:{"input":_vm.programsUpdated,"remove":_vm.handleRemovePrograms},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',{staticClass:"option__title",attrs:{"title":props.option.$groupLabel}},[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"option__title",attrs:{"title":props.option.programDetailDescription}},[_vm._v(_vm._s(props.option.programDescription))])]}}]),model:{value:(_vm.companyInfo.programsOfInterest),callback:function ($$v) {_vm.$set(_vm.companyInfo, "programsOfInterest", $$v)},expression:"companyInfo.programsOfInterest"}},[_c('template',{slot:"selection"},[(_vm.$v.companyInfo.programsOfInterest.length && !_vm.isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(_vm.$v.companyInfo.programsOfInterest.length)+" programs selected ")]):_vm._e()])],2),(
            !_vm.$v.companyInfo.programsOfInterest.required &&
            _vm.$v.companyInfo.programsOfInterest.$dirty
          )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Location Type"}},[_c('multiselect',{class:{
            'multiselect-is-invalid': _vm.$v.companyInfo.locationTypes.$dirty
              ? _vm.$v.companyInfo.locationTypes.$error
              : null,
          },attrs:{"options":_vm.locationTypes,"multiple":true,"close-on-select":false,"placeholder":_vm.$t('dropdown.select-a-location-type'),"track-by":"locationTypeID","label":"locationTypeDesc","group-values":"options","group-label":"label"},on:{"input":_vm.$v.companyInfo.locationTypes.$touch},model:{value:(_vm.companyInfo.locationTypes),callback:function ($$v) {_vm.$set(_vm.companyInfo, "locationTypes", $$v)},expression:"companyInfo.locationTypes"}}),(_vm.validateLocationTypesSelected)?_c('div',{staticClass:"text-danger mt-1"},[_vm._v(" One location type for each program(s) of interest is required ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mb-5"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Affiliate(s)"}},[_c('multiselect',{class:{
            'multiselect-is-invalid': _vm.$v.companyInfo.affiliates.$dirty
              ? _vm.$v.companyInfo.affiliates.$error
              : null,
          },attrs:{"options":_vm.affiliates,"multiple":true,"close-on-select":false,"placeholder":_vm.$t('dropdown.select-an-affiliate'),"track-by":"affiliateID","label":"companyName"},on:{"input":_vm.$v.companyInfo.affiliates.$touch},model:{value:(_vm.companyInfo.affiliates),callback:function ($$v) {_vm.$set(_vm.companyInfo, "affiliates", $$v)},expression:"companyInfo.affiliates"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"save-button-container"},[_c('api-button-overlay',{attrs:{"processing":_vm.processing}},[_c('button',{staticClass:"btn btn-blue",attrs:{"disabled":_vm.formLoading},on:{"click":_vm.saveCompanyInfo}},[_vm._v(" Save ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }