<template>
  <section>
    <h1 class="bold-header-text app-header mb-3">
      <confirmation ref="Confirmation"></confirmation>
      <span v-if="companyInfo.parentCompanyID">Additional Site</span><span v-else>Company</span>
      {{ companyInfo.companyID }}
    </h1>
    <b-row class="mb-2">
      <b-col md="6">
        <b-form-group>
          <LabelWithInfoTootlip
            :label="$t('company.company-legal-name')"
            tooltipText="The legal name of your business must match paperwork sent to your state or federal regulatory bodies."
          />
          <b-form-input
            type="text"
            v-model.trim="companyInfo.companyLegalName"
            :state="
              $v.companyInfo.companyLegalName.$dirty
                ? !$v.companyInfo.companyLegalName.$error
                : null
            "
            @input="$v.companyInfo.companyLegalName.$touch"
          />
          <div class="text-danger" v-if="!$v.companyInfo.companyLegalName.maxLength">
            Must be fewer than 75 characters!
          </div>
          <div
            class="text-danger"
            v-if="
              !$v.companyInfo.companyLegalName.required && $v.companyInfo.companyLegalName.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <LabelWithInfoTootlip
            :label="$t('company.dba-company-name')"
            tooltipText="The Doing Business As (DBA) name is also referred to as an assumed name, fictitious business name, or trade name."
          />
          <b-form-input
            type="text"
            v-model.trim="companyInfo.dbaCompanyName"
            :state="
              $v.companyInfo.dbaCompanyName.$dirty ? !$v.companyInfo.dbaCompanyName.$error : null
            "
            @input="$v.companyInfo.dbaCompanyName.$touch"
          />
          <div class="text-danger" v-if="!$v.companyInfo.dbaCompanyName.maxLength">
            Must be fewer than 75 characters!
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col md="6">
        <b-form-group>
          <LabelWithInfoTootlip
            label="Tax ID"
            tooltipText="A Taxpayer Identification Number (TID) is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entities operating in the United States and its territories."
          />
          <b-form-input
            v-model.trim="companyInfo.maskedFederalIDNumber"
            v-mask="taxMask"
            :state="
              $v.companyInfo.maskedFederalIDNumber.$dirty
                ? !$v.companyInfo.maskedFederalIDNumber.$error
                : null
            "
          />
          <div
            class="text-danger"
            :state="!$v.companyInfo.maskedFederalIDNumber.$error"
            v-if="!$v.companyInfo.maskedFederalIDNumber.minLength"
          >
            Must have at least 9 digits.
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Organization Website">
          <b-form-input
            v-model.trim="companyInfo.website"
            :state="$v.companyInfo.website.$dirty ? !$v.companyInfo.website.$error : null"
            @input="$v.companyInfo.website.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.companyInfo.website.url && $v.companyInfo.website.$dirty"
          >
            Must be a valid URL.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-form-group label="Program(s) of Interest">
          <multiselect
            v-model="companyInfo.programsOfInterest"
            :class="{
              'multiselect-is-invalid': $v.companyInfo.programsOfInterest.$dirty
                ? $v.companyInfo.programsOfInterest.$error
                : null,
            }"
            :options="groupedPrograms"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('dropdown.select-a-program')"
            group-values="options"
            group-label="label"
            label="programDescription"
            track-by="programID"
            :preselect-first="false"
            @input="programsUpdated"
            @remove="handleRemovePrograms"
          >
            <template slot="selection">
              <span
                class="multiselect__single"
                v-if="$v.companyInfo.programsOfInterest.length && !isOpen"
              >
                {{ $v.companyInfo.programsOfInterest.length }}
                programs selected
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <span
                v-if="props.option.$isLabel"
                class="option__title"
                :title="props.option.$groupLabel"
                >{{ props.option.$groupLabel }}</span
              >
              <span v-else class="option__title" :title="props.option.programDetailDescription">{{
                props.option.programDescription
              }}</span>
            </template>
          </multiselect>
          <div
            class="text-danger"
            v-if="
              !$v.companyInfo.programsOfInterest.required &&
              $v.companyInfo.programsOfInterest.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-form-group label="Location Type">
          <multiselect
            v-model="companyInfo.locationTypes"
            :class="{
              'multiselect-is-invalid': $v.companyInfo.locationTypes.$dirty
                ? $v.companyInfo.locationTypes.$error
                : null,
            }"
            :options="locationTypes"
            :multiple="true"
            :close-on-select="false"
            :placeholder="$t('dropdown.select-a-location-type')"
            track-by="locationTypeID"
            label="locationTypeDesc"
            @input="$v.companyInfo.locationTypes.$touch"
            group-values="options"
            group-label="label"
          ></multiselect>
          <div class="text-danger mt-1" v-if="validateLocationTypesSelected">
            One location type for each program(s) of interest is required
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <b-form-group label="Affiliate(s)">
          <multiselect
            v-model="companyInfo.affiliates"
            :class="{
              'multiselect-is-invalid': $v.companyInfo.affiliates.$dirty
                ? $v.companyInfo.affiliates.$error
                : null,
            }"
            :options="affiliates"
            :multiple="true"
            :close-on-select="false"
            :placeholder="$t('dropdown.select-an-affiliate')"
            track-by="affiliateID"
            label="companyName"
            @input="$v.companyInfo.affiliates.$touch"
          ></multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="save-button-container">
        <api-button-overlay :processing="processing">
          <button class="btn btn-blue" @click="saveCompanyInfo" :disabled="formLoading">
            Save
          </button>
        </api-button-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<style lang="scss" scoped>
.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #d02c23 !important;
}
.save-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
<script>
import LabelWithInfoTootlip from "../../../components/Common/LabelWithInfoTooltip.vue";
import Multiselect from "vue-multiselect";
import { mapMutations } from "vuex";
import AffiliateMixin from "../../../mixins/AffiliateMixin.vue";
import CompanyMixin from "../../../mixins/CompanyMixin.vue";
import LocationTypeMixin from "../../../mixins/LocationTypeMixin.vue";
import ProgramMixin from "../../../mixins/ProgramMixin.vue";
import DirtyDataMixin from "../../../mixins/DirtyDataMixin.vue";
import { validationMixin } from "vuelidate";
import Confirmation from "../../../components/Common/Confirmation.vue";
import { bus } from "../../../main";
const { required, minLength, maxLength, helpers } = require("vuelidate/lib/validators");

const url = helpers.regex(
  "url",
  /^((http|ftp|https):\/\/)?(www\.)?([\w-]+\.)+(com\b|edu\b|biz\b|gov\b|in(?:t|fo)\b|mil\b|net\b|org\b|[a-z][a-z]\b)(\/[\w\- ./?]*)?$/
);

export default {
  props: ["companyID"],
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });

    this.formLoading = true;
    await this.getCompanyInfo(this.companyID);
    // await this.getAllLocationTypes();
    await this.getAllAffiliates();
    await this.getAllProgramsByType();

    const selectedProgramIDs = this.companyInfo.programsOfInterest
      .map((program) => program.programID)
      .join();

    if (selectedProgramIDs) {
      await this.getLocationTypesByProgramIDs(selectedProgramIDs);
    }

    this.formLoading = false;
    this.initDirtyDataWatcher("companyInfo");
    this.defaultProgramList = this.companyInfo.programsOfInterest;
    this.defaultCompanyInfo = { ...this.companyInfo };
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  data() {
    return {
      formLoading: false,
      defaultProgramList: [],
      defaultCompanyInfo: {},
    };
  },
  mixins: [
    AffiliateMixin,
    CompanyMixin,
    LocationTypeMixin,
    ProgramMixin,
    validationMixin,
    DirtyDataMixin,
  ],
  validations: {
    companyInfo: {
      companyLegalName: {
        minLength: minLength(2),
        maxLength: maxLength(75),
        required,
      },
      dbaCompanyName: {
        minLength: minLength(2),
        maxLength: maxLength(75),
      },
      maskedFederalIDNumber: {
        minLength: minLength(10),
      },
      website: {
        url,
      },
      locationTypes: {},
      affiliates: {},
      programsOfInterest: {
        required,
      },
    },
  },
  components: {
    Multiselect,
    LabelWithInfoTootlip,
    Confirmation,
  },
  methods: {
    ...mapMutations(["setPreserveMessageAfterPush"]),
    async saveCompanyInfo() {
      this.$v.companyInfo.$touch();
      if (!this.$v.companyInfo.$error) {
        // const programOfInterestSelected = this.companyInfo.programsOfInterest.map((prog) => {
        //   return prog.programID;
        // });
        // const isContainExistingPrograms = _.every(this.defaultProgramList, (program) =>
        //   programOfInterestSelected.includes(program.programID)
        // );
        const isContainExisting = this.ValidateCompareValues(
          this.defaultCompanyInfo,
          this.companyInfo
        );
        if (!isContainExisting) {
          await this.saveCompanyDetails({
            ...this.companyInfo,
            isApplicationCompaniesUpdate: false,
          });
        } else {
          this.$refs.Confirmation.show({
            title: "Update Company Information",
            message: `Saving changes in your company profile will update all applications that have not yet been submitted`,
            okButton: "Yes",
            cancelButton: "No",
            exitFunction: () => {
              this.setInfoMessage("");
              this.saveCompanyDetails({
                ...this.companyInfo,
                isApplicationCompaniesUpdate: false,
              });
            },
          }).then(async (result) => {
            if (result) {
              this.setInfoMessage("");
              this.saveCompanyDetails({
                ...this.companyInfo,
                isApplicationCompaniesUpdate: true,
              });
            }
          });
        }
      } else {
        this.setDangerMessage("Form must be filled out properly.");
      }
    },
    async saveCompanyDetails(payload) {
      this.companyInfo.companyProgramOfInterest = true;
      await this.updateCompanyInfo(payload);
      this.setDirtyData(false);
      this.setPreserveMessageAfterPush(true);
      if (this.statusCode === 200) {
        // this.$emit("handleSave", this.$route.name);
      }
    },
    async programsUpdated() {
      this.$v.companyInfo.programsOfInterest.$touch();
      if (!this.$v.companyInfo.$error) {
        const selectedProgramIDs = this.companyInfo.programsOfInterest
          .map((program) => program.programID)
          .join();
        this.locationTypes = [];
        if (selectedProgramIDs) {
          await this.getLocationTypesByProgramIDs(selectedProgramIDs);
        }
      }
    },
    //Remove the selected options in the dependent dropdowns (Location Type)
    handleRemovePrograms(option) {
      this.companyInfo.locationTypes = this.companyInfo.locationTypes.filter(
        ({ programID }) => programID !== option.programID
      );
    },
    locationTypesAdded() {
      const values = this.companyInfo.locationTypes;
      if (this.locationTypes.length && values && values.length) {
        const selectedTypeIds = values.map(({ locationTypeID }) => locationTypeID);
        const result = this.locationTypes.every(({ options }) =>
          options.some((ele) => selectedTypeIds.includes(ele.locationTypeID))
        );
        return result;
      }
      return false;
    },
    ValidateCompareValues(defaultInfo, companyInfo) {
      // Get the keys of both objects
      const defaultInfoField = Object.keys(defaultInfo);
      const companyInfoField = Object.keys(companyInfo);
      // Check if the number of keys is different
      if (defaultInfoField.length !== companyInfoField.length) {
        return true; // Field changed
      }
      // Iterate over the keys
      for (let key of defaultInfoField) {
        const defaultValue = defaultInfo[key];
        const newCompanyValue = companyInfo[key];
        // Check for null or undefined values
        if (
          defaultValue === null ||
          newCompanyValue === null ||
          defaultValue === undefined ||
          newCompanyValue === undefined
        ) {
          if (defaultValue !== newCompanyValue) {
            return true; // Field changed
          }
        }
        // Recursively compare nested objects
        else if (typeof defaultValue === "object" && typeof newCompanyValue === "object") {
          if (this.ValidateCompareValues(defaultValue, newCompanyValue)) {
            return true; // Field changed
          }
        } else if (defaultValue !== newCompanyValue) {
          return true; // Field changed
        }
      }
      return false; // No field changed
    },
  },
  computed: {
    validateLocationTypesSelected() {
      // Inverse the return data to show the validation message
      return !this.locationTypesAdded();
    },
  },
};
</script>
